'use client'

import { datadogRum } from '@datadog/browser-rum'
import { getClientEnvVariable } from '@/util/environment-variables'
import { useEffect } from 'react'
import { useGlobalSettingsStore } from '@/store'

export default function DatadogInit() {
  const selectedFacilities = useGlobalSettingsStore(state => state.selectedFacilities)
  const currentAccount = useGlobalSettingsStore(state => state.currentAccount)

  useEffect(() => {
    const isLocalhost = window.location.hostname === 'localhost'

    if (!isLocalhost) {
      datadogRum.init({
        applicationId: '1f65cd7e-6cae-4036-9333-05dd5bb98fd7',
        clientToken: 'pubaa72ab17f465ac5f23b51f0e28af516e',
        site: 'us5.datadoghq.com',
        service: 'app',
        env: getClientEnvVariable('NEXT_PUBLIC_APP_ENV'),
        version: process.env.NEXT_PUBLIC_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: getClientEnvVariable('NEXT_PUBLIC_APP_ENV') === 'production' ? 100 : 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        allowedTracingUrls: [
          { match: 'https://app.gratiahealth.com/', propagatorTypes: ['tracecontext'] },
          { match: 'https://app.staging.gratiahealth.com/', propagatorTypes: ['tracecontext'] },
        ],
      })
    }

    datadogRum.setGlobalContextProperty('global_state', {
      selectedFacilities: selectedFacilities,
      currentAccount: currentAccount,
    })
  }, [])

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}
