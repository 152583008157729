type Environment = {
  NEXT_PUBLIC_APP_ENV: string
  NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN: string
}

const env: {
  staging: Environment
  production: Environment
} = {
  staging: {
    NEXT_PUBLIC_APP_ENV: 'staging',
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN:
      'default:development.68b6de0d465eb49d4d235583d1882552ff5e8f55ac957d560eea1899',
  },
  production: {
    NEXT_PUBLIC_APP_ENV: 'production',
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN:
      'default:production.c02b86c17bb911f84d222a913d70ecd81cfd30c66625b7f7dacbcfe5',
  },
}

export function getClientEnvVariable(key: keyof Environment) {
  if (typeof window !== 'undefined') {
    const environment = window.location.hostname === 'app.gratiahealth.com' ? 'production' : 'staging'
    return env[environment][key]
  } else {
    // fallback is we're calling this on the server so this is globally accessible
    const environment = process.env.APP_ENV === 'production' ? 'production' : 'staging'
    return env[environment][key]
  }
}
